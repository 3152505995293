<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    />
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
export default {
  name: 'Form',
  components: { CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      dict: {
        bedAddiDeviceType: []
      },
      formModel: {
        type: '',
        name: '',
        deviceId: ''
      }
    }
  },
  computed: {
    formItem() {
      return {
        'type': {
          'elColSpan': 24,
          'component': 'CustomFormSelect',
          'label': '分类',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.bedAddiDeviceType
        },
        'name': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255
        },
        'deviceId': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '序列号',
          'maxlength': 255
        }
      }
    },
    formRules() {
      return {
        type: [
          { required: true, message: '分类不能为空', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        deviceId: [
          { required: true, message: '序列号不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['getSysDictServe', 'deviceDetail', 'deviceAdd', 'deviceUpdate']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        res.data.forEach(item => {
          const dictPidValArray = item.dictPidVal.split('_')
          if (!(dictPidValArray[1] >= 101 && dictPidValArray[1] <= 107)) {
            this.dict[typeCode].push({
              dictPidVal: item.dictPidVal,
              dictDesc: item.dictDesc
            })
          }
        })
      })
    },
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.getSysDictList('bedAddiDeviceType')
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.deviceDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }
            this.formModel.id = this.formId
            this.formModel.type = res.data.type
            this.formModel.name = res.data.name
            this.formModel.newTile = res.data.newTile
            this.formModel.deviceId = res.data.deviceId
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          switch (this.formStatus) {
            case 'add':
              this.deviceAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.deviceUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
